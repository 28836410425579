<template>
  <div>
    <Content>
      <div class="main-body" id="printMe">
        <div class="select-two main-search">
          <div class="st-block">
            <Select
              v-model="textType"
              @on-change="resetGetInfo"
              style="width:110px"
            >
              <Option
                v-for="(item, index) in devSearchLists"
                :value="item.typeValue"
                :key="index"
                >{{ item.type }}</Option
              >
            </Select>
            <Input
              v-model="text"
              placeholder="请输入搜索内容"
              @on-enter="resetGetInfo"
              style="width: 280px;margin-left:-5px;"
            />
          </div>
          <div class="st-block ml10">
            <div class="st-label">启用状态</div>
            <div class="st-select">
              <Select v-model="deviceType" @on-change="resetGetInfo">
                <Option value="-1">全部</Option>
                <Option
                  v-for="(item, index) in devTypes"
                  :value="item.deviceType"
                  :key="index"
                  >{{ item.name }}</Option
                >
              </Select>
            </div>
          </div>
          <div class="st-block ml10">
            <div class="st-label">所属车间</div>
            <div class="st-select">
              <Select v-model="workshop" @on-change="resetGetInfo">
                <Option value="-1">全部</Option>
                <Option
                  v-for="(item, index) in workshops"
                  :value="item.workshopId"
                  :key="index"
                  >{{ item.workshop }}</Option
                >
              </Select>
            </div>
          </div>
        </div>
        <div class="btn-area">
          <div class="btn-block" @click="addcheck">
            <i class="iconfont iconicon-zengjia"></i>
            &nbsp;&nbsp;新增
          </div>
          <div class="btn-block" @click="editcheck">
            <i class="iconfont iconicon-bianji"></i>
            &nbsp;&nbsp;编辑
          </div>
        </div>
        <div class="table-area" :class="isMin == true ? 'minTableArea' : ''">
          <Table
            no-data-text=" "
            max-height="750"
            class="devTable"
            border
            ref="selection"
            :loading="loading"
            :columns="theadDate"
            :data="tableDate"
            @on-selection-change="getSections"
            @on-sort-change="orderByCode"
            :class="{ minTable: isMin }"
          ></Table>
        </div>
        <div class="main-page">
          <Page
            :total="total"
            show-sizer
            show-elevator
            show-total
            :page-size="pageSize"
            :current="pageNum"
            @on-page-size-change="pagesizechange"
            @on-change="pagenumberchange"
          />
        </div>
      </div>
    </Content>
  </div>
</template>

<script>
export default {
  data() {
    return {
      belongWorkshop: "",
      sections: [],
      delModal: false,
      isMin: false,
      screenWidth: "", // 屏幕宽度
      pageSize: 10,
      pageNum: 1,
      workData: [],
      orderType: "",
      // 顶部筛选
      devSearchLists: [
        {
          type: "机台号",
          typeValue: 1
        },
        {
          type: "设备编号",
          typeValue: 2
        }
      ],
      textType: 1, // 顶部第一个select选择的东西
      text: "", // 顶部搜索内容
      deviceType: "-1",
      devTypes: [
        {
          deviceType: 0,
          name: "停用"
        },
        {
          deviceType: 1,
          name: "启用"
        }
      ], // 设备类型options
      workshop: "-1",
      workshops: [], // 车间options
      weftframenum: "-1",
      weftframenums: [], // 经纬架位数options
      selectDev: [], // 已选中设备
      total: 0,
      pages: 0,
      theadDate: [
        {
          type: "selection",
          align: "center",
          minWidth: 55
        },
        {
          title: "序号",
          render: (h, params) => {
            return h(
              "span",
              params.index + (this.pageNum - 1) * this.pageSize + 1
            );
          },
          align: "center",
          minWidth: 80
        },
        {
          title: "启用状态",
          key: "deviceStatus",
          minWidth: 120,
          render: (h, params) => {
            return h(
              "Select",
              {
                props: {
                  value: params.row.deviceStatus,
                  transfer: true
                },
                style: {
                  width: "100%"
                },
                on: {
                  "on-change": (event) => {
                    // console.log(this.data2[params.index].age)
                    this.changeDeviceStatus(params.row.deviceId, event);
                  }
                }
              },
              [
                h(
                  "Option",
                  {
                    props: {
                      value: 0
                    }
                  },
                  "停用"
                ),
                h(
                  "Option",
                  {
                    props: {
                      value: 1
                    }
                  },
                  "启用"
                )
              ]
            );
          }
        },
        {
          title: "设备编号",
          key: "serialNumber",
          minWidth: 120
        },
        {
          title: "机台号",
          key: "deviceNum",
          minWidth: 100
        },
        {
          title: "设备类型",
          key: "deviceType",
          minWidth: 100
        },
        {
          title: "设备型号",
          key: "modelName",
          minWidth: 100
        },
        {
          title: "所属车间",
          key: "workshop",
          minWidth: 125
        },
        {
          title: "品牌",
          key: "brand",
          minWidth: 125
        },
        {
          title: "入厂日期",
          key: "factoryDate",
          minWidth: 100
        }
      ],
      tableDate: [],
      loading: false,
      selectDeviceList: []
    };
  },
  methods: {
    resetGetInfo() {
      this.pageSize = 10;
      this.pageNum = 1;
      this.searchList();
    },
    orderByCode(column) {
      if (column.order === "asc") {
        this.orderType = 1;
      } else if (column.order === "desc") {
        this.orderType = 2;
      } else {
        this.orderType = "";
      }
      this.searchList();
    },
    // 修改每页条数
    pagesizechange(pagesize, e) {
      this.pageSize = pagesize;
      this.pageNum = 1;
      this.$refs.selection.selectAll(false);
      this.selectDev = [];
      this.searchList();
    },
    // 修改页码
    pagenumberchange(pagenum, e) {
      this.pageNum = pagenum;
      this.$refs.selection.selectAll(false);
      this.selectDev = [];
      this.searchList();
    },
    getSections(e) {
      console.log("哈哈哈哈哈哈                    ,", e);
      const idList = [];
      e.map((item) => {
        idList.push(item.deviceId);
      });
      this.selectDeviceList = idList;
    },
    // 修改设备属性
    changeDeviceStatus(deviceId, deviceStatus) {
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/onOffDevice",
        method: "post",
        data: {
          ztdId: deviceId,
          ztdStatus: deviceStatus
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            this.$Message.success("状态修改成功！");
            this.searchList();
          } else {
            this.searchList();
            this.$Message.error(res.data.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 获取车间列表
    getWorkshops() {
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/workshoplist",
        method: "get",
        params: {}
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.workshops = res.data.body.workshoplist;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 当查询条件变化时调用
    searchList() {
      const that = this;
      this.axios({
        url: "/dtsum/zongtong/device/DeviceController/devicelist",
        method: "get",
        params: {
          pageSize: that.pageSize,
          pageNum: that.pageNum,
          textType: that.textType,
          text: that.text,
          deviceType: that.deviceType === "-1" ? "" : that.deviceType,
          workshop: that.workshop === "-1" ? "" : that.workshop
        }
      })
        .then((res) => {
          if (res.data.success === 1) {
            that.tableDate = res.data.body.devicelist.list;
            that.total = res.data.body.devicelist.total;
            that.pages = res.data.body.devicelist.pages;
            that.pageSize = res.data.body.devicelist.pageSize;
            that.pageNum = res.data.body.devicelist.pageNum;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 新增
    addcheck() {
      this.$router.push({
        path: "/deviceManage/addcheck"
        // query: { pdid: params.row.pdid }
      });
    },
    // 编辑
    editcheck() {
      if (this.selectDeviceList.length === 0) {
        this.$Message.warning("请先选择要编辑的设备");
        return false;
      }
      this.$router.push({
        path: "/deviceManage/checkdetail",
        query: { selectDeviceList: this.selectDeviceList }
      });
    }
  },
  created() {
    this.getWorkshops();
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        window.screenWidth = document.body.clientWidth;
        this.screenWidth = window.screenWidth;
        if (this.screenWidth > 1600) {
          this.isMin = true;
        } else {
          this.isMin = false;
        }
      })();
    };

    this.searchList();
  }
};
</script>

<style lang="scss" scoped>
.main-body {
  width: 100%;
  height: 100%;
  // background: rgba(228,235,241,1);
  padding-bottom: 0;
  .select-two {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .st-block {
      height: 36px;
      line-height: 36px;
      display: flex;
      .st-label {
        margin-right: 10px;
      }
      .st-select {
        width: 108px;
        height: 36px;
      }
      .st-datepicker {
        width: 240px;
        height: 36px;
        .ivu-date-picker {
          width: 110%;
        }
      }
    }
    .ml10 {
      margin-left: 10px;
    }
  }
  .btn-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    .btn-block {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
    }
    .btn-block:hover {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      color: #2980b9;
    }
  }
  // .minTableArea{
  //   overflow-x: scroll;

  // }
  .table-area {
    // overflow:hidden
    // .minTable{
    //   width:1450px;
    // }
    .devTable {
      // width: 200% !important;
      table {
        // position: fixed !important;
      }
    }
    .ivu-table-wrapper /deep/ .ivu-table {
      .ivu-table-body {
        // position: fixed !important;
        tbody .ivu-table-row {
          td .ivu-table-cell .ivu-select .ivu-select-selection {
            border: none;
            box-shadow: none;
          }
        }
        .ivu-table-wrapper /deep/ .ivu-table {
          .ivu-table-body {
            // position: fixed !important;
            tbody .ivu-table-row {
              td .ivu-table-cell .ivu-select .ivu-select-selection {
                border: none;
                box-shadow: none;
              }
              .blueFont /deep/ {
                color: rgba(87, 163, 243, 1);
                .ivu-table-cell .ivu-btn {
                  span {
                    border-bottom: 1px solid rgba(87, 163, 243, 1);
                    height: 18px;
                  }
                }
              }
            }
            tbody .ivu-table-row:hover {
              td .ivu-table-cell .ivu-select .ivu-select-selection {
                border: none;
                box-shadow: none;
                background: rgba(235, 247, 255, 1);
              }
              td .ivu-table-cell .ivu-btn {
                border: none;
                box-shadow: none;
                background: rgba(235, 247, 255, 1);
              }
            }
            // overflow-y: scroll;
            // max-height: 560px;
            // overflow-x: inherit;
          }
        }
        // overflow-y: scroll;
        // max-height: 560px;
        // overflow-x: inherit;
      }
    }
  }
}
// .ivu-table .ivu-table-body tbody .ivu-table-row .ivu-select >>> .ivu-select-selection
.main-body .table-area .devTable >>> .ivu-table >>> .ivu-table-body {
  // background: black;
  // border: none;
  // box-shadow: none;
}
</style>

<style lang="scss">
.ivu-icon,
.ivu-icon-ios-calendar-outline {
  height: $newIpt-height !important;
  line-height: $newIpt-height !important;
}
.mainbody {
  .ivu-tabs-nav-container {
    /*background:rgba(228,235,241,1)*/
  }
  .el-tree-node__content {
    width: 100%;
    min-height: 50px;
    border-bottom: 1px solid rgba(223, 227, 232, 1);
  }
}
</style>
